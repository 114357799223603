<template>
  <div class="texts">
    <b-container fluid="xxl">
      <b-row class="text-center">
        <b-col>
          <input
            class="px-2 py-1 w-100"
            id="search"
            type="text"
            size="sm"
            placeholder="Search texts"
            @keyup="getSearchResults()"
            autocomplete="off"
            v-model="query"
          />
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <ul v-if="texts.length" class="results-ul">
            <li v-for="text in texts" :key="text.id">
              <a href="#" v-if="text.metatext" @click="selectText($event, text)" v-html="text.sigleLegam  + ' [<small>metatext:</small> ' + text.metatext.title + ']'"></a>
              <a href="#" v-else @click="selectText($event, text)" v-html="text.sigleLegam"></a>
            </li>
          </ul>
          <span v-else class="opacity-60">
            <span v-if="query">No results</span>
            <span v-else>Start searching</span>
          </span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'TextAutocomplete',
    props: ['exclude'],
    data() {
      this.$store.state.text.texts = []
      return {
        query: '',
        timer: null
      }
    },
    mounted() {
      this.$store.state.text.texts = []
    },
    computed: {
      ...mapState({
        texts: state => state.text.texts
      }),
      clearQuery(){
        return this.query.replace(/\*/gi, '')
      }
    },
    methods: {
      selectText(event, text){
        event.preventDefault()
        this.$emit('select', text)
        this.$store.state.text.texts = []
        this.query = ''
      },
      getSearchResults(){
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.$store.dispatch('searchTexts', {
            query: this.query.toLowerCase(),
            exclude: (this.exclude ? this.exclude : [])
          })
        }, 400)
      }
    }
  }
</script>
