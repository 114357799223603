<template>
  <div class="authorhistDetails">
    <div v-if="loading || !authorhist">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
            <b-col class="mt-5">
              <div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle v-if="authorhist" v-bind:title="authorhist.sigleLegam" :pretitle="'Historical author details'" />
          </b-col>
          <b-col>
            <div class="mt-5 text-right">
              <span v-if="editMode && config.editable">
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button v-if="$store.getters.checkRole('legam.bib.authorhist.delete')" @click="deleteItem" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else>
                <button @click="editMode = true" v-if="$store.getters.checkRole('legam.bib.authorhist.modify')" class="btn-legam-xs mb-2">
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button v-if="!$store.getters.checkRole('legam.bib.authorhist.modify') && $store.getters.checkRole('legam.bib.authorhist.delete')" @click="deleteItem" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <article>
              <div class="mb-4" v-if="config.editable">
                <p class="mb-1">BIB Labels</p>
                <BibLabelSelect v-if="editMode" :selected="authorhist.biblabels.map(biblabel => biblabel.id)" @select="selectBibLabels" />
                <span v-else>
                  <b-badge v-for="biblabel in authorhist.biblabels" :key="biblabel.id" href="#" class="mr-1" variant="secondary">{{ biblabel.label }}</b-badge>
                </span>
              </div>
              <b-row>
                <b-col>
                  <h6>Sigle LEGaM</h6>
                  <b-form-input
                    id="sigle-legam"
                    v-model="authorhist.sigleLegam"
                    placeholder="Sigle LEGaM"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="authorhist.sigleLegam"></span>
                </b-col>
                <b-col>
                  <h6>Dates Of Life</h6>
                  <b-form-input
                    id="sigle-dates-of-life"
                    v-model="authorhist.datesOfLife"
                    placeholder="Dates Of Life"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="authorhist.datesOfLife"></span>
                </b-col>
                <b-col>
                  <h6>Dates Of Life Num</h6>
                  <b-form-input
                    id="sigle-dates-of-life-num"
                    v-model="authorhist.datesOfLifeNum"
                    placeholder="Dates Of Life Num"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="authorhist.datesOfLifeNum"></span>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col>
                  <h6>Alternative Sigles</h6>
                  <div
                    v-for="siglesource in authorhist.siglesources"
                    :key="siglesource.siglesourceCode"
                    v-html="`${siglesource.alternativeSigle} - ${siglesource.siglesourceCode}`"
                  ></div>
                </b-col>
                <b-col>
                  <h6>Dates Of Activity</h6>
                  <b-form-input
                    id="sigle-dates-of-activity"
                    v-model="authorhist.datesOfActivity"
                    placeholder="Dates Of Activity"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="authorhist.datesOfActivity"></span>
                </b-col>
                <b-col>
                  <h6>Dates Of Life Num</h6>
                  <b-form-input
                    id="sigle-dates-of-activity-num"
                    v-model="authorhist.datesOfActivityNum"
                    placeholder="Dates Of Life Num"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="authorhist.datesOfActivityNum"></span>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col>
                  <h6>Reference</h6>
                  <b-form-textarea
                    id="reference"
                    placeholder="Reference"
                    v-model="authorhist.reference"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else v-html="authorhist.reference"></span>
                </b-col>
                <b-col>
                  <h6>Comment (General)</h6>
                  <b-form-textarea
                    id="comment-general"
                    placeholder="Comment General"
                    v-model="authorhist.commentGeneral"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else class="white-space-pre-line" v-html="authorhist.commentGeneral"></span>
                </b-col>
                <b-col>
                </b-col>
              </b-row>

              <hr>

              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-2 class="text-left">
                      Manuscripts <small>({{ authorhist.manuscripts.length }} {{ authorhist.manuscripts.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-2" accordion="my-accordion-2" role="tabpanel">
                    <b-card-body>
                      <b-card-text>
                        <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-manuscript>
                          <b-icon icon="plus"></b-icon>
                          Add
                        </span>
                        <b-modal id="modal-add-manuscript" title="Add manuscript" ok-only ok-variant="secondary" size="lg">
                          <ManuscriptAutocomplete @select="addManuscript" />
                        </b-modal>
                        <ManuscriptTable
                          :showTotal="false"
                          :manuscripts="authorhist.manuscripts"
                          @delete="deleteManuscript"
                          :editMode="editMode"
                          v-if="authorhist.manuscripts.length"
                        />
                        <span v-else>No data</span>
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 class="text-left">
                      Texts <small>({{ authorhist.texts.length }} {{ authorhist.texts.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-3" accordion="my-accordion-3" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-text>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-text" title="Add study" ok-only ok-variant="secondary" size="lg">
                        <TextAutocomplete @select="addText" />
                      </b-modal>
                      <TextTable
                        :showTotal="false"
                        :texts="authorhist.texts"
                        @delete="deleteText"
                        :editMode="editMode"
                        v-if="authorhist.texts.length"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-4 class="text-left">
                      Studies <small>({{ authorhist.studies.length }} {{ authorhist.studies.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-4" accordion="my-accordion-4" role="tabpanel">
                    <b-card-body>
                      <b-card-text>
                        <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-study>
                          <b-icon icon="plus"></b-icon>
                          Add
                        </span>
                        <b-modal id="modal-add-study" title="Add study" ok-only ok-variant="secondary" size="lg">
                          <StudyAutocomplete @select="addStudy" />
                        </b-modal>
                        <StudyTable
                          :showTotal="false"
                          :studies="authorhist.studies"
                          @delete="deleteStudy"
                          :editMode="editMode"
                          v-if="authorhist.studies.length"
                        />
                        <span v-else>No data</span>
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-7 class="text-left">
                      Alternative Sigles <small>({{ authorhist.siglesources.length }} {{ authorhist.siglesources.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-7" accordion="my-accordion-7" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-siglesource>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-siglesource" title="Add alternative sigle" ok-only ok-variant="secondary" @ok="addSigleSource" size="lg">
                        <SigleSourceRelAutocomplete :sigleSourceRel="modalSigleSourceRel" />
                      </b-modal>
                      <SigleSourceRelTable
                        :showTotal="false"
                        :siglesourcesRel="authorhist.siglesources"
                        @delete="deleteSigleSource"
                        @edit="editSigleSource"
                        :editMode="editMode"
                        v-if="authorhist.siglesources.length"
                        :key="indexSigleSource"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-8 class="text-left">
                      Links <small>({{ authorhist.links.length }} {{ authorhist.links.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-8" accordion="my-accordion-8" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-link>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-link" title="Add link" ok-only ok-variant="secondary" @ok="addLink" size="lg">
                        <LinkAutocomplete :link="modalLink" />
                      </b-modal>
                      <LinkTable
                        :showTotal="false"
                        :links="authorhist.links"
                        @delete="deleteLink"
                        @edit="editLink"
                        :editMode="editMode"
                        v-if="authorhist.links.length"
                        :key="indexLink"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </article>
            <div class="mt-4" v-if="config.editable">
              <h6>Logs</h6>
              <ChangeLogsTable :objectType="'authorhist'" :objectId="authorhistId" :key="'logs' + index" />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import ManuscriptTable from '@/components/Bibliography/ManuscriptTable.vue'
  import ChangeLogsTable from '@/components/ChangeLogsTable.vue'
  import TextTable from '@/components/Bibliography/TextTable.vue'
  import StudyTable from '@/components/Bibliography/StudyTable.vue'
  import ManuscriptAutocomplete from '@/components/Bibliography/ManuscriptAutocomplete.vue'
  import TextAutocomplete from '@/components/Bibliography/TextAutocomplete.vue'
  import StudyAutocomplete from '@/components/Bibliography/StudyAutocomplete.vue'
  import BibLabelSelect from '@/components/Bibliography/BibLabelSelect.vue'
  import SigleSourceRelAutocomplete from '@/components/Bibliography/SigleSourceRelAutocomplete.vue'
  import SigleSourceRelTable from '@/components/Bibliography/SigleSourceRelTable.vue'
  import LinkTable from '@/components/Bibliography/LinkTable.vue'
  import LinkAutocomplete from '@/components/Bibliography/LinkAutocomplete.vue'
  import HelpStore from '@/store/helpstore.js'
  import config from '@/config.js'

  export default {
    name: 'HistoricalAuthorDetails',
    data() {
      return {
        authorhistId: this.$route.params.id,
        loading: true,
        editMode: false,
        index: 0,
        indexSigleSource: 0,
        modalSigleSourceRel: null,
        indexLink: 0,
        modalLink: null,
        config: config,
      }
    },
    components: {
      PageTitle,
      ManuscriptTable,
      TextTable,
      StudyTable,
      ManuscriptAutocomplete,
      TextAutocomplete,
      StudyAutocomplete,
      ChangeLogsTable,
      BibLabelSelect,
      SigleSourceRelAutocomplete,
      SigleSourceRelTable,
      LinkTable,
      LinkAutocomplete,
    },
    computed: {
      ...mapState({
        authorhist: state => state.authorhist.authorhist
      })
    },
    mounted() {
      this.$store.dispatch('loadAuthorhist', this.authorhistId)
    },
    watch: {
      'id'() {
        this.loading = true
        this.$store.dispatch('loadAuthorhist', this.authorhistId)
      },
      'authorhist'() {
        this.loading = false
      }
    },
    methods: {
      addManuscript(manuscript){
        this.$bvModal.hide('modal-add-manuscript')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Manuscript added`
        })
        this.authorhist.manuscripts.push(manuscript)
      },
      deleteManuscript(manuscriptId){
        this.authorhist.manuscripts = this.authorhist.manuscripts.filter(
          manuscript => manuscript.id != manuscriptId
        )
      },
      addText(text){
        this.$bvModal.hide('modal-add-text')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Text added`
        })
        this.authorhist.texts.push(text)
      },
      deleteText(textId){
        this.authorhist.texts = this.authorhist.texts.filter(text => text.id != textId)
      },
      selectBibLabels(biblabels){
        this.authorhist['biblabels'] = biblabels
      },
      addStudy(study){
        this.$bvModal.hide('modal-add-study')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Study added`
        })
        this.authorhist.studies.push(study)
      },
      deleteStudy(studyId){
        this.authorhist.studies = this.authorhist.studies.filter(study => study.id != studyId)
      },
      addSigleSource(){
        this.modalSigleSourceRel = null;
        if (HelpStore.item){
          if (HelpStore.item._index != null){
            this.authorhist.siglesources[HelpStore.item._index] = HelpStore.item;
          }
          else {
            this.authorhist.siglesources.push(HelpStore.item);
          }
          this.indexSigleSource++;
        }
      },
      deleteSigleSource(sigleSourceRelId){
        this.authorhist.siglesources = this.authorhist.siglesources.filter(sigleSourceRel => sigleSourceRel.id != sigleSourceRelId)
      },
      editSigleSource(sigleSourceRel){
        this.modalSigleSourceRel = sigleSourceRel;
        this.$bvModal.show('modal-add-siglesource');
      },

      addLink(){
        this.modalLink = null;
        if (HelpStore.item){
          if (HelpStore.item._index != null){
            this.authorhist.links[HelpStore.item._index] = HelpStore.item;
          }
          else {
            this.authorhist.links.push(HelpStore.item);
          }
          this.indexLink++;
        }
      },
      deleteLink(linkId){
        this.authorhist.links = this.authorhist.links.filter(link => link.id != linkId)
      },
      editLink(link){
        this.modalLink = link;
        this.$bvModal.show('modal-add-link');
      },

      cancelEdit(){
        this.editMode = false
        this.$store.dispatch('loadAuthorhist', this.authorhistId)
      },
      saveEdit(){
        this.editMode = false
        this.$store.dispatch('saveAuthorhist', this.authorhist).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Changes saved`
          })
          this.index++
        })
      },
      deleteItem(){
        this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
          if (value){
            this.$store.dispatch('deleteAuthorhist', this.authorhist).then(() => {
              router.push('/historicalAuthors')
            })
          }
        })
      }
    }
  }
</script>
